<template>
    <div>
        <input
            class="form-control"
            ref="autocomplete"
            placeholder="Enter your company address (you can add multiple)"
            type="text"
        />

        <small class="text-muted mb-2">
            <i>Hint: you can use 'Enter' and arrow keys to select and add</i>
        </small>

        <div v-for="(loc, index) in locations" :key="`${loc.formatted_address}-${index}`">
            <BButton
                class="mr-2 mb-2"
                :variant="loc.headquarters ? 'danger' : 'outline-danger'"
                size="sm"
                v-b-tooltip.html.left="'Set headquarters'"
                @click="changeHeadquarters(index)"
            >
                <FontAwesomeIcon :icon="faBuilding" />
            </BButton>

            <BButton
                class="mr-2 mb-2"
                @click="removeLocation(index)"
                variant="success"
                size="sm"
                v-b-tooltip="'Remove'"
            >
                {{ loc.formatted_address }}
            </BButton>
        </div>
    </div>
</template>
<script>
import { faBuilding } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'GoogleAutocomplete',

    props: {
        locations: {
            type: Array,
            required: true,
            default: () => [],
        },
    },

    model: {
        prop: 'locations',
    },

    mounted() {
        this.autocomplete = new google.maps.places.Autocomplete(this.$refs.autocomplete, {
            types: ['geocode'],
        })

        this.autocomplete.addListener('place_changed', () => {
            let place = this.autocomplete.getPlace()
            this.selectedLocation = place
            this.addLocation()
        })
    },

    data() {
        return {
            selectedLocation: {},
            faBuilding,
        }
    },

    methods: {
        addLocation() {
            const isInputEmpty = this.$refs.autocomplete.value === ''

            if (isInputEmpty) return alert("Field can't be empty")

            const isAddressValid = Object.is(
                this.selectedLocation.address_components[0].types[0],
                'street_number'
            )

            if (isAddressValid) {
                this.$emit('input', [
                    ...this.locations,
                    { ...this.selectedLocation, headquarters: this.locations.length === 0 },
                ])
                this.selectedLocation = {}
            } else {
                alert('Please specify exact address (city, route, street number)')
            }
            this.$refs.autocomplete.value = ''
        },

        changeHeadquarters(index) {
            let updatedLocations = [...this.locations]
            updatedLocations.forEach((item) => (item.headquarters = false))
            updatedLocations[index].headquarters = true
            this.$emit('input', updatedLocations)
        },

        removeLocation(index) {
            let updatedLocations = [
                ...this.locations.slice(0, index),
                ...this.locations.slice(index + 1),
            ]
            if (updatedLocations.length === 1) updatedLocations[0].headquarters = true
            this.$emit('input', updatedLocations)
        },
    },
}
</script>
