<template lang="pug">
  BaseSection
    .card.mt-2.mb-2
      .card-body
        form.mb-3
          .row.justify-content-center
            .col-6
              h2.mb-3.text-center Set up your company
              b-input(
                v-model="companyName"
                placeholder="Company name"
                disabled
              )
              b-form-textarea.mt-2(
                v-model="companyDetails"
                rows='4'
                max-rows='20'
                placeholder="Company details/description"
              )
              div.mt-2
                hr
                h5 Locations
                GoogleAutocomplete.mt-2(
                  v-model="locations"
                )
  
              div.mt-2
                hr
                h5 Departments
                b-input-group  
                  b-input(
                    v-model="departments.new"
                    placeholder="new department name"
                    @keyup.enter="addDepartment"
                  )
                  b-input-group-append
                    b-button(variant="outline-secondary" @click="addDepartment") + Add department
                
                small.text-muted
                  i Hint: you can use 'Enter' key to add
                
                p(v-for="(dep, index) in departments.list") 
                  font-awesome-icon.fa-xs.mr-2(
                    :icon="deleteIcon"
                    @click="removeDepartment(index)"
                    v-b-tooltip.html.left="'Remove department'"
                    style="cursor: pointer"
                  )
                  span {{ dep }}
  
              div.mt-2
                hr
                h5 Company logo
                .text-center
                  img.img-thumbnail(:src="imageData || placeholderImage")
                b-form-file.mt-2(
                  ref="logo"
                  v-model="companyLogo"
                  @change="previewImage"
                  accept="image/jpeg, image/png, image/gif"
                  placeholder="Select company logo"
                  drop-placeholder="Drop image here..."
                )
                small.text-muted
                  i Acceptable formats: .jpg, .jpeg, .png, .gif
  
              div.mt-2
                hr
                h5 GDPR file
                b-form-file.mt-2(
                  ref="gdpr"
                  v-model="companyGdpr"
                  accept=".pdf, .doc, .docx"
                  placeholder="Select GDPR compliance file"
                  drop-placeholder="Drop file here..."
                )
              small.text-muted
                i Acceptable formats: .pdf, .doc, .docx
              .text-center.mt-4
                b-button(variant="success" @click="submitForm") Set up the company

</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import Multiselect from 'vue-multiselect'
import GoogleAutocomplete from '@/components/GoogleAutocomplete'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ROUTE, STORE_MODULE } from '@/enums'
import { BaseSection } from '@/components'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)
const { mapState: mapStateOnboarding } = createNamespacedHelpers(STORE_MODULE.ONBOARDING)

export default {
    components: { BaseSection, Multiselect, GoogleAutocomplete },

    computed: {
        ...mapState(['userAccount']),
        ...mapStateOnboarding(['onboardingState']),

        placeholderImage: (vm) =>
            `http://via.placeholder.com/${vm.imageLimits.width}x${vm.imageLimits.height}`,

        deleteIcon: () => faTrash,
    },

    async created() {
        if (
            !this.userAccount ||
            this.userAccount.userRole !== 'company_admin' ||
            (this.userAccount.userRole === 'company_admin' &&
                this.onboardingState.isCompanySetupCompleted)
        ) {
            this.$router.push({ name: ROUTE.DASHBOARD })
        } else {
            this.companyName = await this.fetchCompanyName()
        }
    },

    data() {
        return {
            companyName: '',
            companyDetails: '',
            locations: [],
            departments: {
                new: '',
                list: [],
            },

            companyLogo: null,
            imageData: null, // for preview
            imageLimits: {
                width: 140,
                height: 80,
            },
            sizeLimit: 1024 * 1024 * 1, // 1 MiB

            companyGdpr: null,
        }
    },

    methods: {
        async fetchCompanyName() {
            try {
                const { data } = await this.$store.state.backend.get('jobBuilder/company/all')
                const userCompany = data.find(
                    (company) => company.id === this.userAccount.userCompanyId
                )

                return userCompany.name
            } catch (error) {
                alert(error.message)
            }
        },

        addDepartment() {
            this.departments.list.push(this.departments.new)
            this.departments.new = ''
        },

        removeDepartment(index) {
            this.departments.list.splice(index, 1)
        },

        validateForm() {
            const throwAlert = (message) => {
                alert(message)
                return false
            }

            if (this.companyDetails === '') return throwAlert("Company details can't be empty")
            if (this.locations.length === 0)
                return throwAlert('At least one location is needed')
            if (this.departments.list.length === 0)
                return throwAlert('At least one department is needed')

            return true
        },

        submitForm() {
            if (!this.validateForm()) return false

            let formData = new FormData()
            formData.append('description', this.companyDetails)
            formData.append('locations', JSON.stringify(this.locations))
            formData.append('departments', JSON.stringify(this.departments.list))
            formData.append('logo', this.companyLogo)
            formData.append('gdpr', this.companyGdpr)

            this.$store.state.backend
                .post('/admin/company-setup', formData)
                .then((res) => {
                    this.$store.commit('setUserCompanySetupCompleted', true)
                    this.$router.push({ name: ROUTE.DASHBOARD })
                })
                .catch((err) => alert(err))
        },

        previewImage(event) {
            const input = event.target
            if (input.files && input.files[0]) {
                if (input.files[0].size > this.sizeLimit)
                    return alert(
                        `File too big. Please upload file that is less than ${
                            this.sizeLimit / 1024
                        }kB size`
                    )

                let reader = new FileReader()
                reader.onload = (e) => {
                    const self = this
                    let image = new Image()
                    image.src = e.target.result
                    image.onload = function () {
                        if (
                            this.height > self.imageLimits.height ||
                            this.width > self.imageLimits.width
                        ) {
                            self.imageData = null
                            alert(
                                `Image can't be higher than ${self.imageLimits.height}px and wider than ${self.imageLimits.width}px`
                            )
                            return false
                        }
                        self.imageData = e.target.result
                        return true
                    }
                }
                reader.readAsDataURL(input.files[0])
            }
        },
    },
}
</script>
